export const namespaced = true;

export const state = () => ({
    podcasts: [],
    categories: [],
    singlePodcast: null,
});

export const getters = {
    allPodcasts: ({ podcasts }) => {
        return podcasts;
    },
    allCategories: ({ categories }) => {
        return categories;
    },
    singlePodcastData: ({ singlePodcast }) => {
        return singlePodcast;
    },
};

export const mutations = {
    setPodcasts(state, data) {
        state.podcasts = data;
    },
    setCategories(state, data) {
        state.categories = data;
    },
    setSinglePodcast(state, data) {
        state.singlePodcast = data;
    },
    clearSinglePodcast(state) {
        state.singlePodcast = null;
    },
};

export const actions = {
    async getAllPodcasts({ commit }) {
        try {
            let categories = await this.$axios.get("/podcast/filters", {
                skip_meta: true
            });
            let response = await this.$axios.get("/podcast");

            if (response && response.data) {
                if (categories && categories.data) {
                    commit("setCategories", categories.data);
                }
                return response.data;
            }
        } catch (error) {
            console.error("Error fetching all podcasts:", error);
        }
    },

    async fetchSinglePodcast({ commit }, { parent_cat_slug, podcast_slug }) {
        try {
            commit("clearSinglePodcast");

            const cleanParentSlug = parent_cat_slug.replace(/^\/podcasts\//, "").replace(/\/$/, "");
            const cleanPodcastSlug = podcast_slug.replace(/^\/podcasts\//, "").replace(/\/$/, "");

            const response = await this.$axios.get("/podcastByslug", {
                params: { parent_cat_slug: cleanParentSlug, podcast_slug: cleanPodcastSlug },
            });

            if (response?.data) {
                commit("setSinglePodcast", response.data);
            } else {
                console.error("No podcast data returned from API.");
                throw new Error("Podcast data is missing.");
            }
        } catch (error) {
            console.error("Error fetching single podcast:", error.message);
            throw error;
        }
    },
};
