
export default {
    mounted() {
        if (process.client) {
            AOS.init({
                duration: 1000,
                once: true,
                offset: 50,
            });
        }
    }
}
