
export default {
    data() {
        return {
            allProducts: [],
            categories: [],
            filteredProducts: [],
            selectedCategory: null,
            currentModalIndex: null,
        };
    },
    async fetch() {
        let { store } = this.$nuxt.context;
        let products = await store.dispatch("product-pricing/getAllProducts");

        if (!products) return;
        this.allProducts = products.all_product_pricing;
        this.categories = store.getters["product-pricing/allCategories"];

        this.filterProducts(null); // Show all products initially
    },
    methods: {
        filterProducts(categoryId) {
            this.selectedCategory = categoryId;

            if (!categoryId) {
                // Show all products if "ALL" is selected
                this.filteredProducts = [...this.allProducts];
                return;
            }

            this.filteredProducts = this.allProducts.filter(product =>
                product.products_filter.some(filter => filter.term_id === categoryId)
            );
        },
        showModal(indexData) {
            this.currentModalIndex = indexData;
            console.log(this.currentModalIndex);

            this.$refs["productDetailsModal"].show();
        },
    }
};
