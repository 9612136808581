
export default {
    mounted() {
        gsap.from(".blue-card-container", {
            scrollTrigger: {
                trigger: ".cloud-ai-blue-card-info-section",
                start: "top 60%",
                end: "top 40%",
                scrub: 1,
            },
            y: 50,
            opacity: 0,
            duration: 1,
        });
    },
}
