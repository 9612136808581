
export default {
    data: () => {
        return {
            activeIndex: 0,
            zIndex: 2,
        };
    },

    mounted() {
        ScrollTrigger.defaults({
            markers: false
        });

        var points = gsap.utils.toArray(".cloudAIPoint");
        let article = gsap.utils.toArray(".article");
        var indicators = gsap.utils.toArray(".indicator");

        var height = 100 * points.length;

        gsap.set(".indicators", { display: "flex" });

        var tl = gsap.timeline({
            duration: points.length,
            scrollTrigger: {
                trigger: ".cloudAI-verical-slider",
                start: "top center",
                end: "+=" + height + "%",
                scrub: true,
                id: "points",
                onUpdate: function (self) {
                    const progress = self.progress;
                    const newActiveIndex = Math.floor(progress * points.length);

                    if (newActiveIndex !== this.activeIndex) {
                        this.activeIndex = newActiveIndex;

                        // Update zIndex here
                        points.forEach((elem, i) => {
                            gsap.set(elem, { zIndex: i === newActiveIndex ? 2 : 1 });
                        });
                    }
                }.bind(this)
            }
        });

        var pinner = gsap.timeline({
            scrollTrigger: {
                trigger: ".cloudAI-verical-slider .cloudAIWrapper",
                start: "top top",
                end: "+=" + height + "%",
                scrub: true,
                pin: ".cloudAI-verical-slider .cloudAIWrapper",
                pinSpacing: true,
                id: "pinning"
                //   markers: true,
            }
        });

        points.forEach((elem, i) => {
            gsap.set(elem, { position: "absolute", top: 50 }, i);

            tl.to(indicators[i], { backgroundColor: "#041A4A", duration: 0.25 }, i);
            tl.from(elem.querySelector(".left-image-container"), { autoAlpha: 0 }, i);
            tl.from(
                article[i],
                { autoAlpha: 0, translateY: 200 },
                i
            );

            if (i != points.length - 1) {
                tl.to(
                    indicators[i],
                    { backgroundColor: "#D9D9D9", duration: 0.25 },
                    i + 0.75
                );
                tl.to(
                    article[i],
                    { autoAlpha: 0, translateY: -200 },
                    i + 0.75
                );
                tl.to(elem.querySelector(".left-image-container"), { autoAlpha: 0 }, i + 0.75);
            }
        });

        // if (process.client) {
        //     gsap.registerPlugin(ScrollTrigger);

        //     const container = document.querySelector(".cloudAI-verical-slider");

        //     const section166 = {
        //         section: document.querySelector(".cloud-ai-hub-section"),
        //         bgColor: "#041A4A",
        //         fontColor: "#FFFFFF",
        //     };

        //     const section167 = {
        //         section: document.querySelector(".cloudAI-verical-slider"),
        //         bgColor: "#FFFFFF",
        //         fontColor: "#000000",
        //     };

        //     function setColors(curr, next) {
        //         let tl = gsap.timeline({ ease: "power2.inOut" });
        //         tl.to(container, {
        //             duration: 1,
        //             backgroundColor: next.bgColor,
        //             color: next.fontColor,
        //         });
        //     }

        //     gsap.set(container, {
        //         backgroundColor: section167.bgColor,
        //         color: section167.fontColor,
        //     });

        //     gsap.to(section166.section, {
        //         scrollTrigger: {
        //             trigger: section166.section,
        //             start: "top 55%",
        //             end: "top 25%",
        //             markers: true,
        //             onEnter: () => setColors(section167, section166),
        //             onLeaveBack: () => setColors(section166, section167),
        //         },
        //     });
        // }
    }
};
