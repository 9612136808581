
import TataLink from "~/components/global-components/tata-link";
import TataArrowLink from "~/components/global-components/tata-arrow-link";
import TataPrimaryCta from "~/components/global-components/tata-primary-cta";
import { Glide, GlideSlide } from "vue-glide-js";

export default {
    components: {
        TataLink,
        TataArrowLink,
        TataPrimaryCta,
        [Glide.name]: Glide,
        [GlideSlide.name]: GlideSlide
    },
    props: {
        // eslint-disable-next-line vue/require-default-prop
        podcastData: {
            type: Object,
            Default: {}
        },
        podcastIndex: {
            type: Number,
            default: ""
        }
    },
    data: () => {
        return {
            imgSlider: {
                focusAt: 0,
                startAt: 0,
                autoplay: 2000,
                perView: 1,
                type: "carousel",
                dragThreshold: false,
                bullet: false,
                gap: 30,
                breakpoints: {
                    992: {
                        perView: 1,
                        gap: 10,
                        type: "carousel"
                    }
                }
            },
            modalImgSlider: {
                focusAt: 0,
                startAt: 0,
                autoplay: 2000,
                perView: 1,
                type: "carousel",
                dragThreshold: false,
                bullet: false,
                gap: 30,
                breakpoints: {
                    992: {
                        perView: 1,
                        gap: 10,
                        type: "carousel"
                    }
                }
            },
            modalSwiper: null,
            currentModalIndex: null,
            isPlaying: false,
            volume: 1,
            currentTime: 0,
            duration: 0,
            waveSurfer: null,
            podcastFileUrl: "",
        };
    },
    methods: {
        showModal(podcastIndex) {
            this.currentModalIndex = podcastIndex;
            this.$refs["awardDetailsModal"].show();
            this.podcastFileUrl = this.podcastData.podcast_file.url;
        },
        initializeModalGlide() {
            console.log("Glide initialized");
            // Dynamically initialize Glide component after modal is shown
            const awardId = this.podcastData.id;
            const podcastIndex = this.currentModalIndex;
            const glideSliderRef = `glide_slider_${awardId}_${podcastIndex}`;

            this.$nextTick(() => {
                // Ensure that Glide component is initialized only when the modal is shown
                const glideComponent = this.$refs[glideSliderRef];
                if (glideComponent && glideComponent.glide) {
                    glideComponent.glide.mount();
                }
            });
        },
        modalCurrentSliderChange(ref) {
            this.$nextTick(() => {
                let reference = this.$refs[`glide_slider_${this.podcastData.id}_${this.currentModalIndex}`];

                if (reference && reference.$el && reference.$el.parentNode) {
                    let currentIndexElement = reference.$el.parentNode.querySelector(".awards-recognition--current-index");

                    if (currentIndexElement) {
                        currentIndexElement.innerHTML = reference.glide.index + 1;
                    }
                }
            });
        },
        readMore: function (event) {
            var target = event.target;
            target.closest(`li`).classList.add("active");
            target.closest(".read-more").classList.add("d-none");
            document.querySelector(".awards-recognition").classList.add("active");

            if (document.documentElement.clientWidth >= 992) {
                let boxHeight = target
                    .closest("li")
                    .querySelector(".awards-recognition--list-item__aside").offsetHeight;

                target
                    .closest("li")
                    .querySelector(
                        ".awards-recognition--list-item--details__inner .editor-data"
                    ).style.height = boxHeight - 90 + "px";
            }
        },
        detailsClose: function (event) {
            var target = event.target;
            target.closest(`li`).classList.remove("active");
            target
                .closest(".awards-recognition--list-item__aside")
                .querySelector(".read-more")
                .classList.remove("d-none");
            document.querySelector(".awards-recognition").classList.remove("active");
        },
        currentSliderChange(ref) {
            let reference = this.$refs[`glide_slider_${this.podcastData.id}`];
            reference.$el.parentNode.querySelector(
                ".awards-recognition--current-index"
            ).innerHTML = reference.glide.index + 1;
        },
        // initializeAudioPlayer() {
        //     this.$nextTick(() => {
        //         if (typeof WaveSurfer !== 'undefined') {
        //             this.audioPlayer = WaveSurfer.create({
        //                 container: '#waveform',
        //                 waveColor: 'violet',
        //                 progressColor: 'purple',
        //             });

        //             this.audioPlayer.load(this.podcastFileUrl);
        //             console.log('Audio player initialized with URL:', this.podcastFileUrl);
        //         } else {
        //             console.error('WaveSurfer library not loaded.');
        //         }
        //     });
        // },
        initializeAudioPlayer() {
            console.log("Initializing Audio Player");
            this.$nextTick(() => {
                if (typeof WaveSurfer !== "undefined") {
                    // Destroy existing WaveSurfer instance
                    if (this.waveSurfer) {
                        this.waveSurfer.destroy();
                    }

                    // Create new WaveSurfer instance
                    try {
                        this.waveSurfer = WaveSurfer.create({
                            container: "#waveform",
                            waveColor: "#757575ab",
                            progressColor: "#3985C8",
                            height: 70,
                            barWidth: 3,
                            barGap: 1.5,
                            cursorWidth: 0,
                            barRadius: 2,
                        });

                        // Load the audio file
                        this.waveSurfer.load(this.podcastFileUrl);

                        // Event: Audio ready
                        this.waveSurfer.on("ready", () => {
                            this.duration = this.waveSurfer.getDuration();
                        });

                        // Event: Play
                        this.waveSurfer.on("play", () => {
                            this.isPlaying = true;
                        });

                        // Event: Pause
                        this.waveSurfer.on("pause", () => {
                            this.isPlaying = false;
                        });

                        // Event: Audio process (progress update)
                        this.waveSurfer.on("audioprocess", () => {
                            this.currentTime = this.waveSurfer.getCurrentTime();
                        });

                    } catch (error) {
                        console.error("Error initializing WaveSurfer:", error);
                    }
                } else {
                    console.warn("WaveSurfer is not defined");
                }
            });
        },

        togglePlay() {
            if (this.waveSurfer) {
                this.waveSurfer.playPause();
            }
        },

        rewind(seconds) {
            if (this.waveSurfer) {
                const time = Math.max(this.waveSurfer.getCurrentTime() - seconds, 0);
                this.waveSurfer.seekTo(time / this.duration);
            }
        },
        forward(seconds) {
            if (this.waveSurfer) {
                const time = Math.min(this.waveSurfer.getCurrentTime() + seconds, this.duration);
                this.waveSurfer.seekTo(time / this.duration);
            }
        },
        changeVolume() {
            if (this.waveSurfer) {
                this.waveSurfer.setVolume(this.volume);
            }
        },
        stopAudio() {
            if (this.waveSurfer) {
                this.waveSurfer.pause();
            }
        },
        formatTime(seconds) {
            const minutes = Math.floor(seconds / 60);
            const secs = Math.floor(seconds % 60);
            return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
        },

    },
    mounted() {
        // Extract ID from URL
        const hash = window.location.hash;
        const idFromUrl = hash ? hash.substring(1) : null;
        // Check if idFromUrl is null
        if (idFromUrl === null) {
            return;
        }
        const sectionId = idFromUrl;

        // Apply offset if needed
        const element = document.getElementById(sectionId);
        setTimeout(() => {
            if (element) {
                const yOffset = 200; // Change this value to your desired offset
                const yCoordinate = element.getBoundingClientRect().top + window.scrollY;
                window.scrollTo({ top: yCoordinate - yOffset, behavior: 'smooth' });
            }
        }, 1000);

        if (typeof WaveSurfer !== 'undefined') {
            this.initializeWaveSurfer();
        } else {
            console.error('WaveSurfer is not loaded!');
        }
    }
};
