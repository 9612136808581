
export default {
  data() {
    return {
      popupPdf: false,
      filtered_list: [],
      allData: false,
      mainPdf: false,
      counter: 9,
      showLoadmorButton: true,
      filter: {
        type: this.$nuxt.$route.query.category_type || "all"
      },
      domain_name: false,
      threatAdvisory: [],
      quaterlyReport: [],
      quaterly_Filtered_List: [],
      quaterlyData: false,
      quaterlyPdf: false,
      activeTab: 'Weekly',
    };
  },
  watch: {
    filter: {
      deep: true,
      handler({ type }) {
        try {
          if (this.activeTab === 'Weekly') {
            if (type.length === 0 || type === "all") {
              this.filtered_list = this.allData;
              this.$router.push({
                query: { ...this.$route.query, category_type: undefined }
              });
              return;
            } else {
              this.$router.push({
                query: { ...this.$route.query, category_type: type }
              });
            }
            this.counter = 9;
            let filterResult = this.allData.filter((e) => {
              let typeReturn = true;
              if (e.category.length > 0) {
                typeReturn = e.category.indexOf(type) > -1;
              } else typeReturn = false;

              return typeReturn;
            });
            this.filtered_list = filterResult;
            if (this.filtered_list.length > 9) {
              this.showLoadmorButton = true;
            } else {
              this.showLoadmorButton = false;
            }
          }
          if (this.activeTab === 'Quaterly') {
            if (type.length === 0 || type === "all") {
              this.quaterly_Filtered_List = this.quaterlyData;
              this.$router.push({
                query: { ...this.$route.query, category_type: undefined }
              });
              return;
            } else {
              this.$router.push({
                query: { ...this.$route.query, category_type: type }
              });
            }
            this.counter = 9;
            let quaterlyFilterResult = this.quaterlyData.filter((e) => {
              let typeReturn = true;
              if (e.category.length > 0) {
                typeReturn = e.category.indexOf(type) > -1;
              } else typeReturn = false;

              return typeReturn;
            });
            this.quaterly_Filtered_List = quaterlyFilterResult;
            if (this.quaterly_Filtered_List.length > 9) {
              this.showLoadmorButton = true;
            } else {
              this.showLoadmorButton = false;
            }
          }
        } catch (e) {
          console.error(e);
        }
      }
    }
  },
  mounted() {
    if (this.$attrs.list && this.$attrs.list['threat-advisory']) {
      this.threatAdvisory = this.$attrs.list['threat-advisory']; /* adding threat-advisory data to threatAdvisory data property */
    } else {
      console.log("threatAdvisory is not defined in the attributes.");
      return;
    }
    if (this.$attrs.list && this.$attrs.list['quarterly-reports']) {
      this.quaterlyReport = this.$attrs.list['quarterly-reports']; /* adding quarterly-advisory data to quaterlyReport data property */
    } else {
      console.log("Quarterly Reports is not defined in the attributes.");
      return;
    }
    this.domain_name = window.location.protocol + "//" + window.location.host;

    /* Latest PDF url of threat-advisory resource is added in MainPdf data property */
    this.mainPdf = this.domain_name + "/pdfjs/web/viewer.html?url=" + this.threatAdvisory[0].pdf.url;
    /* Latest PDF url of quarterly-reports resource is added in quaterlyPdf data property */
    if (this.quaterlyReport.length > 0) {
      this.quaterlyPdf = this.domain_name + "/pdfjs/web/viewer.html?url=" + this.quaterlyReport[0].pdf.url;
    } else {
      this.quaterlyPdf = this.domain_name + "/pdfjs/web/viewer.html?url=" + 'this.quaterlyReport[0].pdf.url';
    }

    this.allData = this.threatAdvisory;
    this.filtered_list = this.allData;

    this.quaterlyData = this.quaterlyReport;
    this.quaterly_Filtered_List = this.quaterlyData;

    if (this.filter.type !== "all") {
      let type = parseInt(this.filter.type);
      if (this.threatAdvisory) {
        this.filtered_list = this.allData.filter((e) => {
          let typeReturn = true;
          if (e.category.length > 0) {
            typeReturn = e.category.indexOf(type) > -1;
          } else typeReturn = false;

          return typeReturn;
        });
        if (this.filtered_list.length > 9) {
          this.showLoadmorButton = true;
        } else {
          this.showLoadmorButton = false;
        }
      }
      if (this.quaterlyReport) {
        this.quaterly_Filtered_List = this.quaterlyData.filter((e) => {
          let typeReturn = true;
          if (e.category.length > 0) {
            typeReturn = e.category.indexOf(type) > -1;
          } else typeReturn = false;

          return typeReturn;
        });
        if (this.quaterly_Filtered_List.length > 9) {
          this.showLoadmorButton = true;
        } else {
          this.showLoadmorButton = false;
        }
      }
    }
  },
  methods: {
    openPopup(pdf) {
      this.popupPdf = pdf;
      this.$refs["pdf_popup"].show();
    },
    loadMore() {
      this.counter = this.counter + 9;
      if (this.activeTab === 'Weekly') {
        if (this.filtered_list.length <= this.counter) {
          this.showLoadmorButton = false;
          return;
        }
      } else {
        if (this.quaterly_Filtered_List.length <= this.counter) {
          this.showLoadmorButton = false;
          return;
        }
      }
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
  }
};
