
export default {
    mounted() {
        if (window.innerWidth > 992) {
            const cards = document.querySelectorAll(".white-card-main-container .card-wrapper");
            cards.forEach((card, index) => {
                card.style.left = -(index * 308) + 'px';
            });

            cards.forEach(element => {
                gsap.to(cards, {
                    left: 0,
                    stagger: 0.1,
                    scrub: true,
                    scrollTrigger: {
                        trigger: document.querySelector(".digital-fabric-white-card-section"),
                        start: 'top center',
                        end: 'top center',
                    }
                })
            });
        }

    }
}
