
export default {
    head() {
        if (!this.$attrs.script_editor) return {};

        return {
            script: [
                {
                    type: "application/ld+json",
                    innerHTML: this.$attrs.script_editor,
                    body: true
                }
            ]
        };
    }
};
