
export default {
    data: () => {
        return {
            cloudAICardSwiper: null,
            expandedIndex: 0,
        };
    },
    computed: {
        processedHorizontalCardData() {
            const cardData = this.$attrs.horizontal_card_data || [];
            return cardData.length == 3 ? [...cardData, ...cardData] : cardData;
        },
    },
    mounted() {
        this.cloudAICardSwiper = new Swiper(".cloudAICardSwiper", {
            slidesPerView: 3,
            spaceBetween: 30,
            grabCursor: false,
            keyboard: {
                enabled: true,
            },
            autoplay: {
                delay: 6000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            navigation: {
                nextEl: ".custom-next-nav",
                prevEl: ".custom-prev-nav",
            },
            breakpoints: {
                300: {
                    centeredSlides: true,
                    initialSlide: 0,
                    slidesPerView: 1.2,
                    spaceBetween: 10,
                    loop: true,
                },
                768: {
                    slidesPerView: 2.2,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                    centeredSlides: true,
                    loop: true,
                },
            },

        });
        if (process.client) {
            AOS.init({
                duration: 1000,
                delay: 500,
                once: false,
                offset: 50,
            });
        }

        this.$nextTick(() => {
            window.AOS.refresh();
        });
    },
    methods: {
        toggleFaq(index) {
            const accordionItem = document.querySelectorAll('.collapse')[index];
            if (this.expandedIndex === index) {
                accordionItem.style.height = `${accordionItem.scrollHeight}px`;
                requestAnimationFrame(() => {
                    accordionItem.style.height = '0px';
                });
                this.expandedIndex = null;
            } else {
                if (this.expandedIndex !== null) {
                    const previousItem = document.querySelectorAll('.collapse')[this.expandedIndex];
                    previousItem.style.height = `${previousItem.scrollHeight}px`;
                    requestAnimationFrame(() => {
                        previousItem.style.height = '0px';
                    });
                }
                accordionItem.style.height = '0px';
                requestAnimationFrame(() => {
                    accordionItem.style.height = `${accordionItem.scrollHeight}px`;
                });
                this.expandedIndex = index;
            }
        },
        makeSlideActive(index) {
            if (this.cloudAICardSwiper) {
                this.cloudAICardSwiper.slideToLoop(index, 500);
            }
        },
    }
}
