export const state = () => ({
    products: [],
    categories: []
});
export const getters = {
    allProducts: ({ products }) => {
        return products;
    },
    allCategories: ({ categories }) => {
        return categories;
    }
};
export const mutations = {
    setproducts(state, data) {
        state.products = data;
    },
    setCategories(state, data) {
        state.categories = data;
    }
};
export const actions = {
    async getAllProducts({ commit }) {
        let categories = await this.$axios.get("/products-pricing/filters", {
            skip_meta: true
        });
        let response = await this.$axios.get("/products-pricing");
        if (response && response.data) {
            if (categories && categories.data) {
                commit("setCategories", categories.data);
            }
            return response.data;
        }
    }
};
