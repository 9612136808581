

export default {
    mounted() {
        gsap.from(".benefits-card-contain", {
            transform: "translateX(85%)",
            scrollTrigger: {
                trigger: ".desktop-animation",
                markers: false,
                start: "top 18%",
                end: "top -45%",
                scrub: 3,
                pin: true
            }
        })
    }
}
