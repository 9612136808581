
import gsap from "gsap";
export default {
  data() {
    return {
      formData: []
    };
  },
  mounted() {
    this.formData = this.$attrs;
    this.formData.eventData = {
      event: "TataFormSubmit",
      category: "Form submit",
      action: "Page",
      label: window.location.href
    };
    if (process.client) {
      gsap.registerPlugin(ScrollTrigger);

      gsap.from(".cloudAI__form__data", {
        scrollTrigger: {
          trigger: ".marketo-form-data",
          start: "top 90%",
          end: "top 60%",
          scrub: 0.9,
        },
        x: -50,
        opacity: 0,
        duration: 1,
      });
      gsap.from(".cloudAI__form", {
        scrollTrigger: {
          trigger: ".marketo-form-data",
          start: "top 90%",
          end: "top 60%",
          scrub: 0.9,
        },
        x: 50,
        opacity: 0,
        duration: 1,
      });
    }
  },
  methods: {
    isFormSubmitted() {
      if (this.$attrs.is_gmq_form) {
        document.getElementsByClassName("form-section")[0].classList.add("d-none");
        document.getElementsByClassName("gmq-download-section")[0].classList.remove("d-none");
        window.scrollTo({
          top: document.getElementsByClassName("gmq-download-section")[0].offsetTop - 150,
          behavior: "smooth"
        });
      }
    }
  }
};
