
export default {
    data: () => {
        return {
            activeIndex: 0,
            zIndex: 2,
            whiteBg: 'whiteBg'
        };
    },

    mounted() {
        ScrollTrigger.defaults({
            markers: false
        });

        var points = gsap.utils.toArray(".staticImageVideoPoint");
        let article = gsap.utils.toArray(".article");
        var indicators = gsap.utils.toArray(".indicator");

        var height = 100 * points.length;

        gsap.set(".indicators", { display: "flex" });

        var tl = gsap.timeline({
            duration: points.length,
            scrollTrigger: {
                trigger: ".new-slider-with-static-image-section",
                start: "top center",
                end: "+=" + height + "%",
                scrub: true,
                id: "points",
                onUpdate: function (self) {
                    const progress = self.progress;
                    const newActiveIndex = Math.floor(progress * points.length);

                    if (newActiveIndex !== this.activeIndex) {
                        this.activeIndex = newActiveIndex;

                        // Update zIndex here
                        points.forEach((elem, i) => {
                            gsap.set(elem, { zIndex: i === newActiveIndex ? 2 : 1 });
                        });
                    }
                }.bind(this)
            }
        });

        var pinner = gsap.timeline({
            scrollTrigger: {
                trigger: ".new-slider-with-static-image-section .staticImageVideoWrapper",
                start: "top top",
                end: "+=" + height + "%",
                scrub: true,
                pin: ".new-slider-with-static-image-section .staticImageVideoWrapper",
                pinSpacing: true,
                id: "pinning"
                //   markers: true,
            }
        });

        points.forEach((elem, i) => {
            gsap.set(elem, { position: "absolute", top: 50 }, i);

            tl.to(indicators[i], { backgroundColor: "#041A4A", duration: 0.25 }, i);
            // tl.from(elem.querySelector(".left-image-container"), { autoAlpha: 0 }, i);
            tl.from(
                article[i],
                { autoAlpha: 0, translateY: 200 },
                i
            );

            if (i != points.length - 1) {
                tl.to(
                    indicators[i],
                    { backgroundColor: "#D9D9D9", duration: 0.25 },
                    i + 0.75
                );
                tl.to(
                    article[i],
                    { autoAlpha: 0, translateY: -200 },
                    i + 0.75
                );
                // tl.to(elem.querySelector(".left-image-container"), { autoAlpha: 0 }, i + 0.75);
            }
        });
    }
};
