
export default {
    data() {
        return {
            isMobile: false,
        };
    },
    computed: {
        backgroundImage() {
            return this.isMobile && this.$attrs.section_bg_mobile
                ? this.$attrs.section_bg_mobile.url
                : this.$attrs.section_bg.url;
        },
    },
    mounted() {
        if (typeof window !== "undefined") {
            this.isMobile = window.innerWidth <= 768; // Initialize `isMobile` on mount
            window.addEventListener("resize", this.updateIsMobile);
        }

        if (process.client) {
            gsap.registerPlugin(ScrollTrigger);

            const container = document.querySelector(".cloud-ai-hub-section");

            const section166 = {
                section: document.querySelector(".cloud-ai-hub-section"),
                bgColor: "#041A4A",
                fontColor: "#FFFFFF",
            };

            const section168 = {
                section: document.querySelector(".new-cloud-ai-swipe-card-section"),
                bgColor: "#FFFFFF",
                fontColor: "#000000",
            };

            function setColors(curr, next) {
                let tl = gsap.timeline({ ease: "power2.inOut" });
                tl.to(container, {
                    duration: 1,
                    backgroundColor: next.bgColor,
                    color: next.fontColor,
                });
            }

            gsap.set(container, {
                backgroundColor: section166.bgColor,
                color: section166.fontColor,
            });

            gsap.to(section168.section, {
                scrollTrigger: {
                    trigger: section168.section,
                    start: "top 55%",
                    end: "top 25%",
                    onEnter: () => setColors(section166, section168),
                    onLeaveBack: () => setColors(section168, section166),
                },
            });
        };

        var tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".cloud-ai-hub-section",
                start: "top 80%",
                end: "top 20%",
                scrub: 3,
                ease: 'power2.inOut',
            },
        });

        tl.from('.line1-contain', {
            x: 100,
            opacity: 0,
            duration: 1.5,
            stagger: 0.2,
        });
        tl.from('.line2-contain', {
            x: -100,
            opacity: 0,
            duration: 1.5,
            stagger: 0.4,
        });
        tl.from('.Info-contianer', {
            x: -100,
            opacity: 0,
            scrub: 2,
            duration: 2,
            stagger: 0.5,
            delay: 0.5,
        });
        tl.from('.hub-image-container', {
            x: 100,
            opacity: 0,
            scrub: 2,
            duration: 2,
            stagger: 0.6,
            delay: 0.7,
        });

    },
    beforeUnmount() {
        if (typeof window !== "undefined") {
            window.removeEventListener("resize", this.updateIsMobile);
        }
    },
    methods: {
        updateIsMobile() {
            const wasMobile = this.isMobile;
            this.isMobile = window.innerWidth <= 768;
            if (wasMobile !== this.isMobile) {
                // The `backgroundImage` will automatically update because it’s a computed property
            }
        },
    },
}
