
import { gsap } from "gsap";
export default {
    data() {
        return {
            ctaBorderColor: 'white-bg-bolor',
            bannerUrl: "",
        };
    },
    mounted() {
        if (process.client) {
            AOS.init({
                duration: 1000,
                once: true,
                offset: 20,
            });

            gsap.registerPlugin(ScrollTrigger);

            const container = document.querySelector(".cloud-ai-image-content-section");
            const borderColor = document.querySelector(".white-bg-bolor");
            // const blueCta = document.querySelector(".blue-cta");


            const section164 = {
                section: document.querySelector(".cloud-ai-image-content-section"),
                bgColor: "#041A4A",
                fontColor: "#FFFFFF",
            };

            const section165 = {
                section: document.querySelector(".new-cloud-ai-card-section"),
                bgColor: "#FFFFFF",
                fontColor: "#000000",
            };

            if (section165.section) {
                function setColors(curr, next) {
                    let tl = gsap.timeline({ ease: "power2.inOut" });
                    tl.to(container, {
                        duration: 1,
                        backgroundColor: next.bgColor,
                        color: next.fontColor,
                    });
                    tl.to(borderColor, {
                        duration: 1,
                        background: 'linear-gradient(#ffffff, #ffffff) padding-box, conic-gradient(from var(--angle), #34425E, #00A4FF) border-box !important',
                    })
                }

                gsap.set(container, {
                    backgroundColor: section164.bgColor,
                    color: section164.fontColor,
                });

                gsap.to(section165.section, {
                    scrollTrigger: {
                        trigger: section165.section,
                        start: "top 55%",
                        end: "top 25%",
                        onEnter: () => setColors(section164, section165),
                        onLeaveBack: () => setColors(section165, section164),
                    },
                });

            }
        };
        if (process.client && window.innerWidth <= 768) {
            document.querySelectorAll("[data-aos]").forEach(el => el.removeAttribute("data-aos"));
        }
        gsap.from(".image-container", {
            scrollTrigger: {
                trigger: ".cloud-ai-image-content-section",
                start: "top 60%",
                end: "top 40%",
                scrub: 3,
            },
            x: 100,
            opacity: 0,
            delay: 10,
            duration: 3,
            ease: "sine.out",
        });

    },
    methods: {
        showBannerModal(val) {
            this.$refs["bannerModal"].show();
            const videoSrc = val;
            var videoSrcNew = videoSrc + "/?autoplay=1&rel=0&mute=1";
            this.bannerUrl = videoSrcNew;
        },
    },
}
